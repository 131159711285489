import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { useParams } from "react-router-dom";



function Redirect() {
  const { slug } = useParams();
  const [urls, setUrls] = useState([]);



  // Youtube token
  const youtubeToken = 'QUFFLUhqbExRcElrWXBPTElSWkotVDcwZUNlb2xyRDlFZ3xBQ3Jtc0tsanNYblFMcGJMNXFyWG02UDZQdXJSQlJEWkpBQV92a3lqTGYteU1HWV82cEVWUHdiZWNqUkhFRy1pU2xOZFNWMGRkVVI1bEVUaG1mbmVYLXJ5aXZDU0luVFJuZlhibnNIaWotMXFBOVFOeUtZQ1FRZw';



  useEffect(() => {
    let query = db.collection("urls").where("slug", "==", slug);
    query.onSnapshot((data) => {
      if (data.empty) {
        alert("URL not found");
      }
      let finalData = data.docs[0].data();
      setUrls(
        [
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
          finalData.url,
        ].map((url) => encodeURIComponent(url))
      );
    });
  }, [slug]);


  if (urls.length > 0) {
    let randomIndex = Math.floor(Math.random() * urls.length);
    let redirectUrl = `vnd.youtube://youtube.com/redirect?event=comments&redir_token=${youtubeToken}&q=${urls[randomIndex]}&html_redirect=1`;;
    window.location.href = redirectUrl;
  }

  return (
    <h2 className="text-center text-success italic">Success</h2>
  );
}

export default Redirect;
